const $ = require('jquery');
import lazysizes from 'lazysizes';
import LuminusGallery from '../../node_modules/luminous-lightbox/dist/Luminous.min.js';

document.addEventListener('DOMContentLoaded', () => {
    console.log('hi dom');
    // Init lazy load images sizes
    window.lazySizesConfig = window.lazySizesConfig || {};
    window.lazySizesConfig.expand = 300; // default 360-500
    lazySizesConfig.expFactor = 1.5; // default: 1.7

    const options = {
        sourceAttribute: "href",
        injectBaseStyles: false,
    };

    new LuminousGallery(document.querySelectorAll('a[target="_blank"][href*="d303o144obivgv.cloudfront.net"]:not([class*="new-window"])'), options);

    // Refresh form tokens
    let forms = document.querySelectorAll('.formie-form');
    forms.forEach( (form) => {
        let csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
        let formHandleInput = form.querySelector('input[name="handle"]');
        let formHandle = formHandleInput.value;
        // Fetch the new token for the form and replace the CSRF input with our new one
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/actions/formie/forms/refresh-tokens?form=${formHandle}`);

        // request state change event
        xhr.onreadystatechange = function() {

            // request completed?
            if (xhr.readyState !== 4) return;

            if (xhr.status === 200) {
                // request successful - show response
                let response = JSON.parse(xhr.responseText);
                csrfInput.outerHTML = response.csrf.input;

                if (response.captchas && response.captchas.javascript) {
                    // JavaScript captcha
                    let jsCaptcha = response.captchas.javascript;
                    form.querySelector('input[name="' + jsCaptcha.sessionKey + '"]').value = jsCaptcha.value;
                }

                // Find the Duplicate captcha hidden input, so we can update it
                if (response.captchas && response.captchas.duplicate) {
                    // Duplicate captcha
                    let duplicateCaptcha = response.captchas.duplicate;
                    form.querySelector('input[name="' + duplicateCaptcha.sessionKey + '"]').value = duplicateCaptcha.value;
                }

                // Update the form's hash (if using Formie's themed JS)
                if (form.form && form.form.formTheme) {
                    form.form.formTheme.updateFormHash();
                }
            }
            else {
                // request error
                console.log('HTTP error', xhr.status, xhr.statusText);
            }
        };

        // start request
        xhr.send();
    });

    const navToggle = document.querySelector('.navbar-toggler');
    if (navToggle) {
        navToggle.addEventListener('click', () => {
            if (navToggle.getAttribute('aria-label') === 'Open navigation') {
                navToggle.setAttribute('aria-label', 'Close navigation');
            } else {
                navToggle.setAttribute('aria-label', 'Open navigation');
            }
        });
    }

    const globalHeader = document.getElementById('global-site-header');
    if (globalHeader) {
        const main = document.getElementById('main');
        let globalHeaderHeight = globalHeader.offsetHeight;
        let globalHeaderOffset = globalHeader.offsetTop;
        let windowWidth = window.innerWidth;
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > globalHeaderOffset && windowWidth >= 1200) {
                globalHeader.classList.add('fixed-top');
                main.style.marginTop = `62px`;
            } else {
                globalHeader.classList.remove('fixed-top');
                main.style.marginTop = '';
            }
        });

        if (windowWidth >= 991) {
            const links = document.querySelectorAll('.nav-link[data-id]');
            const sections = document.querySelectorAll('.anchor');

            if (sections.length !== 0) {
                function changeLinkState() {
                    for (let i = 0; i < sections.length; i++) {
                        if (sections[i].offsetTop <= window.pageYOffset + 192) {
                            links.forEach((link) => link.classList.remove('active'));
                            links[i].classList.add('active');
                        }
                    }
                }

                changeLinkState();
                window.addEventListener('scroll', changeLinkState);
            }
        }

        const resizeCallback = () => {
            window.addEventListener('resize', () => {
                globalHeaderHeight = globalHeader.offsetHeight;
                globalHeaderOffset = globalHeader.offsetTop;
                windowWidth = window.innerWidth;
            });
        }

        resizeCallback();

    }
});